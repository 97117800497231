import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';

const PharmacyDashboard = lazy(() => import('../components/Pharmacy/Dashboard'));
const PharmacyFillPrescription = lazy(() => import('../components/Pharmacy/FillPrescription'));
const PharmacyClaims = lazy(() => import('../components/Pharmacy/Claims'));
const PharmacyClaimDetails = lazy(() => import('../components/Pharmacy/Claims/ClaimDetails'));
const PharmacyFileClaim = lazy(() => import('../components/Pharmacy/FileClaim'));
const PharmacyPreAuths = lazy(() => import('../components/Pharmacy/Pre-Auth'));

const Notifications = lazy(() => import('../components/Notifications'));

const PharmacyPre_Auth = lazy(() => import('../components/Pharmacy/Pre-Auth'));

const Profile = lazy(() => import('../components/Profile'));
const Support = lazy(() => import('../components/Support'));
const Drugs = lazy(() => import('../components/Drugs'));
const Payments = lazy(() => import('../components/Payments'));
const PaymentDetails = lazy(() => import('../components/PaymentDetails'));

export const pharmacy = (
  <>
    <Route
      path="/"
      element={
        <PrivateRoute>
          <PharmacyDashboard />
        </PrivateRoute>
      }
    />
    <Route
      path="/prescriptions"
      element={
        <PrivateRoute>
          <Navigate to={'/'} />
        </PrivateRoute>
      }
    />
    <Route
      path="/prescriptions/:prescription_id"
      element={
        <PrivateRoute>
          <PharmacyFillPrescription />
        </PrivateRoute>
      }
    />
    <Route
      path="/file-claim"
      element={
        <PrivateRoute>
          <PharmacyFileClaim />
        </PrivateRoute>
      }
    />
    <Route
      path="/payments"
      element={
        <PrivateRoute>
          <Payments />
        </PrivateRoute>
      }
    />
    <Route
      path="/payments/:batchId"
      element={
        <PrivateRoute>
          <PaymentDetails />
        </PrivateRoute>
      }
    />
    <Route
      path="/preauthorizations"
      element={
        <PrivateRoute>
          <PharmacyPreAuths />
        </PrivateRoute>
      }
    />
    <Route
      path="/claims"
      element={
        <PrivateRoute>
          <PharmacyClaims />
        </PrivateRoute>
      }
    />
    <Route
      path="/claims/:claim_id"
      element={
        <PrivateRoute>
          <PharmacyClaimDetails />
        </PrivateRoute>
      }
    />
    <Route
      path="/support"
      element={
        <PrivateRoute>
          <Support />
        </PrivateRoute>
      }
    />
    <Route
      path="/profile"
      element={
        <PrivateRoute>
          <Profile />
        </PrivateRoute>
      }
    />
    <Route
      path="/payments"
      element={
        <PrivateRoute>
          <Payments />
        </PrivateRoute>
      }
    />
    <Route
      path="/payments/:batchId"
      element={
        <PrivateRoute>
          <PaymentDetails />
        </PrivateRoute>
      }
    />
    <Route
      path="/notifications"
      element={
        <PrivateRoute>
          <Notifications />{' '}
        </PrivateRoute>
      }
    />
    <Route
      path="/drugs"
      element={
        <PrivateRoute>
          <Drugs />
        </PrivateRoute>
      }
    />
  </>
);